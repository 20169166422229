import Typography from "typography"
import FairyGates from "typography-theme-fairy-gates"

FairyGates.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "a": {
      textShadow: `none`,
      backgroundImage: `none`,
      color: `rgb(30, 115, 190)`,
    },
    "a:hover": {
      color: `rgba(30, 115, 190, 0.7)`,
    },
    "pre, code": {
      fontSize: `0.9em !important`
    },
    "blockquote": {
      borderLeftColor: `rgb(30, 115, 190)`,
    },
    "header > h1 > a": {
      color: `rgb(30, 115, 190) !important`,
    },
    "header > h3 > a": {
      color: `rgb(30, 115, 190) !important`,
    },
    "header > h3 > a:hover": {
      color: `rgba(30, 115, 190, 0.7) !important`,
    }
  }
}

const typography = new Typography(FairyGates)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
